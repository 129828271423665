<template>

    <div class="login-page">
        <div>
            <h4 class="mb-4">Login</h4>
            <p>Welcome back, please login to your account.</p>
        </div>
        <span class="error" v-if="error">{{error}}</span>
        <ap-form :fields="fields" :values="values" :buttons="buttons" @change="login"/>

    </div>

</template>

<script>
    import {toast} from "../../utils/toast";
    import ApForm from "../../components/ApForm";

    export default {

        components: {ApForm},

        // Data
        data() {
            return {
                loading: false,
                checkbox_remember_me: false,
                error: ""
            }
        },
        created() {
            console.log('here')
        },
        computed: {

            // Form fields list
            fields()
            {
                return {
                    email: {type:"string", validation: ["required"], title: "eMail"},
                    password: {type:"password", validation: ["required"], title: this.$t("ttlPassword")},
                };
            },

            values()
            {
                // No values
                return {};
            },

            // Buttons list
            buttons() {

                // Return list
                return {login: {title: this.$t('btLogin'), type: "submit"}}
            }

        },
        methods: {

            // check member login
            checkLogin() {
                // If member is already logged in notify
                if (this.$store.state.auth.currentUser) {
                    toast.notify(this, 'Login Attempt', `You are already logged in!`, 'warning');
                    return false
                }
                return true
            },

            // Logging in
            async login(data) {
                if (!this.checkLogin()) return;

                // Loading
                this.loading = true;

                const payload = {
                    checkbox_remember_me: 1,
                    email: data.email,
                    password: data.password
                };

                this.$store.dispatch('auth/login', payload)
                    .then(() => {
                        // Navigate User to homepage
                        return this.$router.push({name: 'profile'});
                    })
                    .catch(error => {
                        // close loader
                        this.loading = false;
                        this.error = error.message || 'Invalid credentials'
                    })
            },

            // Register
            register() {
                if (!this.checkLogin()) return;
                this.$router.push('/pages/register').catch(() => {})
            }
        }
    }
</script>

<style lang="scss">
    .login-page {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding-top: 80px;
    }
</style>
