export const toast = {
    // show notify toast
    notify(context, title, text, variant = '', timeout = 2000) {
        let options = {
            title: title,
            autoHideDelay: timeout,
        };
        if(variant) options['variant'] = variant;

        context.$bvToast.toast(text, options);
    }
};
